import { components, GroupBase, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import cn from 'classnames';
import css from 'bundles/App/pages/CartPage/MiddleSection/SimplyShip/SimplyShip.scss';
import * as React from 'react';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import SmartySDK from 'smartystreets-javascript-sdk';
import AppContext from 'contexts/AppContext/AppContext';
import { cartAddressFieldClick, cartEnterAddress } from 'api/gtm';
import OrderContext from 'contexts/OrderContext/OrderContext';
import SimplyShipContext from 'contexts/SimplyShipContext/SimplyShipContext';
import { daysBetweenDates } from 'utils/time';

function debounceFunctionExecutionOnceEveryDelayMs(func: (...args) => void, delayInMs: number) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delayInMs);
  };
}

const { Option } = components;
const CustomOption = ({
  children,
  ...props
}: OptionProps<unknown, boolean, GroupBase<{ suggestion: unknown }>>) => {
  const numEntries = (props.data as { suggestion: { entries: number }; value: Address })?.suggestion?.entries;
  return (
    <Option {...props} className="!flex items-center">
      <div>{children}</div>
      {numEntries > 1 && <span>&gt;</span>}
    </Option>
  );
};

interface Props {
  setAddress?: (address: Address) => void;
  disableInput?: boolean;
  controlClassName?: string;
}
const SmartyAutocomplete = ({ disableInput, setAddress, controlClassName }: Props) => {
  const appContext = React.useContext(AppContext);
  const { order } = React.useContext(OrderContext);
  const simplyShipContext = React.useContext(SimplyShipContext);

  function getConvertedDate(dateString) {
    if (!dateString) {
      return new Date();
    }

    return new Date(appContext.keepTimezoneConvertTime(dateString));
  }

  const SmartyCore = SmartySDK.core;
  const Lookup = SmartySDK.usAutocompletePro.Lookup;
  const [client, setClient] =
    React.useState<
      SmartySDK.core.Client<SmartySDK.usAutocompletePro.Lookup, SmartySDK.usAutocompletePro.Lookup>
    >(null);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(null);
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!disableInput) {
      const key = appContext.env.smartyClientKey;
      const credentials = new SmartyCore.SharedCredentials(key);
      const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
        'us-autocomplete-pro-cloud',
      ]);
      setClient(clientBuilder.buildUsAutocompleteProClient());
    }
  }, []);

  React.useEffect(() => {
    if (selectedSuggestion) {
      ref.current.onInputChange(`${selectedSuggestion.streetLine} ${selectedSuggestion.secondary}`);
    }
  }, [selectedSuggestion]);

  const smartyToPrintivityAddress = React.useCallback(
    (smartyAddress: SmartySDK.usAutocompletePro.Suggestion): Address => {
      let whiteSpace = '';
      let secondary = '';
      if (smartyAddress.secondary) {
        if (smartyAddress.entries > 1) {
          secondary = `${smartyAddress.secondary} (${smartyAddress.entries} entries)`;
        } else {
          secondary = smartyAddress.secondary;
        }
        whiteSpace = ' ';
      }

      const newAddress: Address = {
        name: 'price quote',
        address1: `${smartyAddress.streetLine + whiteSpace + secondary}`,
        address2: '',
        company: '',
        city: smartyAddress.city,
        zipcode: smartyAddress.zipcode,
        stateId: appContext.store.states.find(s => s.abbr === smartyAddress.state).id,
        countryId: 214,
        phone: '1111111111',
      };

      return newAddress;
    },
    [appContext.store.states],
  );

  const buildAddress = suggestion => {
    let whiteSpace = '';
    let secondary = '';
    if (suggestion.secondary) {
      if (suggestion.entries > 1) {
        secondary = `${suggestion.secondary} (${suggestion.entries} entries)`;
      } else {
        secondary = suggestion.secondary;
      }
      whiteSpace = ' ';
    }
    return `${suggestion.streetLine + whiteSpace + secondary} ${suggestion.city}, ${suggestion.state} ${
      suggestion.zipcode
    }`;
  };

  const getAddresses = React.useCallback(
    (value, callback) => {
      const lookup = new Lookup(value);
      lookup.maxResults = 10;
      lookup.excludeStates = ['PR', 'VI', 'GU', 'AS', 'PW', 'FM', 'MP', 'MH', 'AE', 'AA', 'AP'];
      if (selectedSuggestion) {
        lookup.selected = buildAddress(selectedSuggestion).replace(' entries', '').replace(',', '');
      }

      client.send(lookup).then(res => {
        cartAddressFieldClick({
          button_name: 'address_field',
          action: res.result.length === 0 ? 'failure' : 'success',
        });

        callback(
          res.result.map(suggestion => ({
            value: smartyToPrintivityAddress(suggestion),
            label: buildAddress(suggestion),
            suggestion,
          })),
        );
      });
    },
    [Lookup, selectedSuggestion, client, smartyToPrintivityAddress],
  );
  return (
    <AsyncSelect
      ref={ref}
      classNamePrefix="select_prefix"
      styles={{
        control: (base, state) => ({
          // I tried to use tailwind but it was always overrided by react-select styles
          ...base,
          borderColor: state.isFocused ? '#00aeef' : '#d1d5db',
          boxShadow: '0 !important',
          ':hover': {
            borderColor: state.isFocused ? '#00aeef' : '#d1d5db',
          },
        }),
      }}
      classNames={{
        control: () => cn('!rounded-lg !border-2 !border-solid ', controlClassName),
        input: () => cn(css.innerInputField, 'min-w-max w-full'),
        option: () => 'async-react-select-options',
      }}
      cacheOptions
      isClearable
      loadOptions={debounceFunctionExecutionOnceEveryDelayMs(getAddresses, 300)}
      defaultValue={null}
      defaultOptions={[{ value: '', label: 'Enter your address...' }]}
      onChange={(option: { suggestion: { entries: number }; value: Address }) => {
        if (option) {
          if (option.suggestion.entries > 1 && selectedSuggestion === null) {
            setSelectedSuggestion(option.suggestion);
          } else {
            cartAddressFieldClick({
              button_name: 'address_field',
              action: 'success',
            });

            cartEnterAddress({
              default_date: simplyShipContext.newArrival.deliveryDate,
              line_items: order.lineItems.map(elem => ({
                product: elem.product.name,
                item_id: elem.product.slug,
                quantity: elem.quantity,
                ...elem.description,
              })),
              default_subtotal: order.subtotal,
              default_total: order.total,
              default_shipping_price: order.shippingTotal,
              default_turnaround: daysBetweenDates(
                getConvertedDate(simplyShipContext?.newArrival?.deliveryDate),
                getConvertedDate(simplyShipContext?.newArrival?.readyAtDate),
              ),
            });
            setSelectedSuggestion(null);
            setAddress(option.value);
            setOpen(false);
          }
        }
      }}
      noOptionsMessage={() => 'Could not find any verified addresses matching your search'}
      placeholder="Enter your address..."
      closeMenuOnSelect={false}
      menuIsOpen={open}
      onMenuOpen={() => setOpen(true)}
      onMenuClose={() => {
        setOpen(false);
        setSelectedSuggestion(null);
      }}
      components={{
        Option: CustomOption,
      }}
      onInputChange={() => {
        setSelectedSuggestion(null); // clear selected suggestion when user types (presses backspace after selecting an address with entries)
      }}
    />
  );
};

export default SmartyAutocomplete;
